import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import RedoIcon from '@mui/icons-material/Redo';
import {
  Alert,
  Box,
  IconButton,
  Link,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Collections,
  createAuditNonce,
  Firebase,
  useAgent,
  useAgentScheduleA,
  UserRoles,
} from '@ozark/common';
import {
  AgentScheduleA,
  ComingSoon,
  ConfirmationDialog,
  Loading,
  Title,
} from '@ozark/common/components';
import {useSiteJumpAsUser} from '@ozark/common/hooks/useSiteJumpAsUser';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useNotification} from '../../hooks/useNotification';
import {useStore} from '../../store/helpers';
import {Tab, Tabs} from '../Tabs';
import {AgentAttachments} from './AgentAttachments';
import {AgentNotes} from './AgentNotes';
import {AgentEdit} from './Edit';
import {MerchantSupportModel} from './MerchantSupportModel/MerchantSupportModel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      display: 'flex',
      margin: theme.spacing(0, 1, 0, 1),
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 2, 1, 2),
    },
  })
);

enum AgentTabs {
  Info,
  ProcessorConfiguration,
  MerchantSupportModel,
  ScheduleA,
  Notes,
  Attachments,
}

const DEFAULT_TAB = 0;

const AgentDetails = () => {
  const {authUser} = useStore();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [tab, setTab] = useState(DEFAULT_TAB);
  const {id} = useParams<{id: string}>();
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const showNotification = useNotification();
  const {authProfile, isUserAdmin} = useStore();
  const {siteJumpAsUser, loading: siteJumpLoading} = useSiteJumpAsUser();

  const isAdmin = isUserAdmin();

  const canEdit =
    authProfile.data?.role === UserRoles.admin || authProfile.data?.role === UserRoles.agentSupport;

  const allowAddComments = authProfile.data
    ? [
        UserRoles.admin,
        UserRoles.support,
        UserRoles.agentSupport,
        UserRoles.underwriter,
        UserRoles.operations,
      ].includes(authProfile.data?.role)
    : false;

  const {
    document: {data: agent},
  } = useAgent(id);

  // do not load scheduleA if have no access
  const {document: scheduleA, addNewVersion: setScheduleA} = useAgentScheduleA(
    authUser.data && canEdit ? id : ''
  );

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (query.has('tab')) {
      const newTab = Number(query.get('tab'));
      setTab(newTab || DEFAULT_TAB);
    }
  }, [history.location.search]);

  if (!agent || !authUser.data) return <Loading />;
  const isSubAgent = !!agent && agent.masterUid;

  const handleDeleteAgent = () => async () => {
    try {
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const data = {deleted: !agent.deleted, auditNonce};

      const snapshot = await Firebase.firestore.collection(Collections.agents).doc(agent.id).get();

      if (snapshot.exists) {
        await snapshot.ref.set(data, {merge: true});
      }

      showNotification('success', 'User successfully deleted.');

      history.goBack();
    } catch (err: any) {
      console.error(`failed to delete document. ${err.toString()}`);
      showNotification('error', (err as Error).message);
    }
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.AGENTS)}
                >
                  Agents
                </Link>,
                <Typography variant="body1">{`${agent.firstName} ${agent.lastName}`}</Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Agent Information" value={AgentTabs.Info} />
          <Tab label="Processor Configuration" value={AgentTabs.ProcessorConfiguration} />
          <Tab label="Merchant Support Model" value={AgentTabs.MerchantSupportModel} />
          <Tab
            label="Schedule A"
            disabled={!canEdit || scheduleA.promised}
            value={AgentTabs.ScheduleA}
          />
          <Tab label="Notes" value={AgentTabs.Notes} />
          <Tab label="Attachments" value={AgentTabs.Attachments} />
        </Tabs>
      </Title>
      {tab === AgentTabs.Info && (
        <>
          <Paper>
            {!agent.deleted ? (
              <div className={classes.actions}>
                <IconButton
                  className={classes.deleteButton}
                  onClick={() => siteJumpAsUser({uid: id})}
                  size="large"
                  disabled={siteJumpLoading}
                >
                  <Tooltip title="Jump to Portal">
                    <RedoIcon />
                  </Tooltip>
                </IconButton>
                {canEdit && (
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => setConfirmationAction(() => handleDeleteAgent())}
                    size="large"
                  >
                    <Tooltip title="Delete Agent">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </div>
            ) : null}
            {!canEdit ? (
              <Box sx={{pt: 2, px: 2}}>
                <Alert severity="info">
                  You currently have <b>read only</b> access to this page.
                </Alert>
              </Box>
            ) : null}
            <AgentEdit agentId={agent.id} isAuthUserAdmin={canEdit} />
          </Paper>
        </>
      )}
      {tab === AgentTabs.MerchantSupportModel && <MerchantSupportModel isReadOnly={!canEdit} />}
      {tab === AgentTabs.ScheduleA && !scheduleA.promised && (
        <AgentScheduleA
          document={scheduleA.data}
          parentScheduleAParams={
            isSubAgent
              ? {
                  id: agent.masterUid,
                  type: 'agent',
                }
              : {
                  id: agent?.group.id,
                  type: 'group',
                }
          }
          set={setScheduleA}
          applyDefaults={true}
          allowNegatives={isAdmin}
          riskTogglesEnabled={false}
          readonly={!canEdit}
          displayCalculationMethod
          agentId={agent?.id}
        />
      )}
      {tab === AgentTabs.Attachments && <AgentAttachments agentId={id} />}
      {tab === AgentTabs.Notes && <AgentNotes allowAddComments={allowAddComments} agentId={id} />}
      {![
        AgentTabs.Info,
        AgentTabs.ScheduleA,
        AgentTabs.Attachments,
        AgentTabs.MerchantSupportModel,
        AgentTabs.Notes,
      ].includes(tab) && <ComingSoon />}
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you want to delete this agent?`}
        onClose={() => setConfirmationAction(null)}
        onConfirm={confirmationAction}
      />
    </Box>
  );
};

export default AgentDetails;
